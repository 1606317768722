import React from "react"

import { useState, useEffect } from "react"
import ModalVideo from "react-modal-video"
import { VideoFrame } from "../../styles/components/VideoFrame"
import OptimizedImage from "../../utils/optimizedImage"
import { StaticImage } from "gatsby-plugin-image"
import { PlayButton } from "../../utils/IconsList"
import {
  VideoSectionWrapper,
  Content,
  TextContent,
  KickerWrapper,
  HeadingWrapper,
  SubheadingWrapper,
  ImageWrapper,
  ThumbnailWrapper,
  BackgroundWrapper,
} from "./styles/VideoSectionStandard.styled"

import onKeyDown from "../../utils/onKeyDown"
import { validatePlay } from "../../utils/validateVideo"
import setScript from "../../utils/setScript"

export const VideoSectionStandard = ({ component }) => {
  const {
    featuredVideo,
    kicker,
    videoHeading,
    subheadingText,
    background,
    javascriptSnippet,
    htmlSnippet,
  } = component
  const backgroundColor = background?.toLowerCase()
  const jsSnip = javascriptSnippet?.javascriptSnippet
  const htmlSnip = htmlSnippet?.htmlSnippet
  const hasEmbed = jsSnip && htmlSnip

  const [play, setPlay] = useState(false)
  const [videoId, setVideo] = useState()
  const [channel, setChannel] = useState("")

  const playVideo = featuredVideo => {
    validatePlay(featuredVideo, setChannel, setVideo, setPlay)
  }

  useEffect(() => {
    if (hasEmbed) {
      setScript(jsSnip)
    }
  }, [hasEmbed])

  return (
    <>
      <VideoSectionWrapper
        background={backgroundColor}
        noTopPadding={component?.noTopPadding}
      >
        <Content>
          <TextContent>
            {kicker && <KickerWrapper>{kicker}</KickerWrapper>}
            {videoHeading && <HeadingWrapper> {videoHeading} </HeadingWrapper>}
            {subheadingText?.subheadingText && (
              <SubheadingWrapper>
                {" "}
                {subheadingText?.subheadingText}{" "}
              </SubheadingWrapper>
            )}
          </TextContent>
          <ImageWrapper hasEmbed={hasEmbed}>
            {backgroundColor === "blue" && (
              <BackgroundWrapper>
                <StaticImage src="../../../static/images/video-section-background.png" />
              </BackgroundWrapper>
            )}
            {!hasEmbed ? (
              <ThumbnailWrapper
                onClick={() => playVideo(featuredVideo)}
                role="button"
                aria-label="play"
                tabIndex={0}
                onKeyDown={e =>
                  onKeyDown(e, () => playVideo(featuredVideo), "Enter")
                }
              >
                <figure className="blog-image-wrapper">
                  {featuredVideo?.thumbnail?.url ? (
                    <div>
                      <OptimizedImage
                        image={featuredVideo?.thumbnail?.gatsbyImageData}
                        src={featuredVideo?.thumbnail?.url}
                        alt={featuredVideo?.thumbnail?.description || featuredVideo?.thumbnail?.title || "featured-image"}
                      />
                    </div>
                  ) : (
                    <div className="emptyImg" />
                  )}
                </figure>
                <div className="playicon">
                  <span>
                    <PlayButton />
                  </span>
                </div>
              </ThumbnailWrapper>
            ) : (
              <div
                className="embed-wrapper"
                dangerouslySetInnerHTML={{ __html: htmlSnip }}
              />
            )}
          </ImageWrapper>
        </Content>
        {typeof window !== "undefined" && (
          <VideoFrame>
            <ModalVideo
              channel={channel}
              isOpen={play}
              videoId={videoId}
              url={channel === "custom" && videoId}
              onClose={() => setPlay(false)}
              autoplay={true}
            />
          </VideoFrame>
        )}
      </VideoSectionWrapper>
    </>
  )
}
