import styled, { css } from "styled-components"

export const VideoSectionWrapper = styled.div`
  ${props =>
    props.background && props.background === "grey"
      ? `background: #f6f6f6;`
      : `background: white;`}
  padding: 96px 0 0;
  position: relative;
  overflow: hidden;
  @media (max-width: 992px) {
    padding: 64px 0 0;
  }
  ${({ noTopPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
    }
  `}
`

export const Content = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 30px;
`

export const TextContent = styled.div`
  margin: 0 auto;
  max-width: 770px;
  @media (max-width: 768px) {
    max-width: 506;
  }
`

export const KickerWrapper = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #002dc2;
  margin-bottom: 24px;
`

export const HeadingWrapper = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #1b1b1b;
  @media (max-width: 992px) {
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 42px;
  }
`

export const SubheadingWrapper = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #475467;
  margin-top: 16px;
`

export const ImageWrapper = styled.div`
  margin: 64px 0;
  aspect-ratio: 2.58;
  ${props =>
    props?.hasEmbed &&
    css`
      aspect-ratio: 16/9;
      max-height: 65vh;
      margin: 64px auto;
    `}
  box-shadow: 0px 12px 20px 5px rgba(27, 27, 27, 0.15);
  @media (max-width: 768px) {
    margin: 40px 0;
    ${props =>
      props?.hasEmbed &&
      css`
        margin: 40px auto;
      `}
  }
  .blog-image-wrapper {
    max-width: 1200px;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      object-fit: cover;
    }
    :after {
      content: "";
      border-radius: 8px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
    }
    .emptyImg {
      width: 100%;
      max-height: 800px;
      min-height: 450px;
      background: #d4d9e2;
      @media (max-width: 992px) {
        width: 100%;
        min-width: auto;
      }
      @media (max-width: 768px) {
        min-height: 300px;
      }
    }
  }
`
export const ThumbnailWrapper = styled.div`
  position: relative;
  .playicon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      filter: drop-shadow(0px 10px 40px rgba(0, 34, 183, 0.3));
      circle,
      path {
        transition: ease all 0.25s;
      }
    }
  }
  :hover {
    cursor: pointer;
    svg {
      circle {
        fill: #0022b7;
      }
      path {
        fill: white;
      }
    }
  }
`

export const BackgroundWrapper = styled.div`
  position: absolute;
  min-width: 100vw;
  height: 100%;
  left: 0;
  margin-top: 96px;
  .gatsby-image-wrapper {
    min-height: 100%;
    width: 100%;
  }
  @media (max-width: 992px) {
    margin-top: 64px;
  }
  @media (max-width: 768px) {
    margin-top: 40px;
  }
`
