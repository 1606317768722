import React from 'react';
import { VideoSectionStandard } from './VideoSectionStandard';

const VideoSection = ({ component }) => {
  const {
    type
  } = component
  switch (type) {
    case "standard":
      return <VideoSectionStandard component={component} />;
    default: 
      return <VideoSectionStandard component={component} />;
  }
}

export default VideoSection
